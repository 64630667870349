<template>
	<div class="goodsList">
		<div class="flex">
			<NavMenu @getList2='getList'>123</NavMenu>
			<div class="content">
				<div class="cate">
					{{
						(
							$store.state.cateArr.find(item => {
								return item.categoryId == this.$route.query.cateId;
							}) || {}
						).categoryName
					}}
				</div>
				<div class="filter">
					<div class="main">
						<div class="options">
							<div class="item" @click="currentCate = $route.query.cateId">{{ $t('tip.qb') }}</div>
							<div class="devide"></div>
							<template v-for="val in sons">
								<div class="item" @click="currentCate = val.categoryId">{{ val.categoryName }}</div>
								<div class="devide"></div>
							</template>
							<!-- <div class="devide"></div> -->
							<!-- 	<div class="item">排列方式</div>
					<div class="devide"></div>
					<div class="item">筛选器</div>
					<div class="item">颜色</div>
					<div class="item">尺寸</div>
					<div class="item">系列</div> -->
						</div>
						<!-- <div class="total">{{total}}{{$t('cart.unit')}}{{$t('cart.good')}}</div> -->
					</div>
				</div>
				<div class="list">
					<div class="item" v-for="val in list"><GoodsItem :detail="val" @getList='getList'></GoodsItem></div>
				</div>

				<!--list.length 和 pageSize一样说明还可能有,需要展示loadMore-->
				<div class="more" @click="loadMore" v-if="list.length == page.size">{{ $t('nav.more') }}</div>
			</div>
		</div>
		<!-- <div class="ad">
			<img class="regular" src="../../assets/sources/banner.png">
		</div> -->
	</div>
</template>

<script>
import GoodsItem from '../../components/Goods/listItem.vue';
import Api from '../../api/request.js';
import NavMenu from '../../components/Base/navMenu.vue';
export default {
	name: 'GoodsList',
	components: {
		GoodsItem,
		NavMenu
	},
	data() {
		return {
			page: {
				page: 1,
				size: 12
			},
			list: [],
			total: 0,
			currentCate: '',
			sons: []
		};
	},
	watch: {
		$route() {
			this.currentCate = this.$route.query.cateId;
			this.findChild();
		},
		currentCate() {
			this.getList();
		}
	},
	created() {
		this.currentCate = this.$route.query.cateId;
		this.findChild();
	},
	methods: {
		findChild() {
			this.sons = this.$store.state.cateList.find(item => {
				return item.categoryId == this.$route.query.cateId;
			});
			this.sons = this.sons ? this.sons.sons : [];
		},
		getList(label_id,money) {
			// this.$loading.start();
			console.log(label_id,money)
			Api.Goods.list({
				categoryId: this.currentCate,
				page: this.page.page,
				pageLimit: this.page.size,
				label_id:label_id?label_id[0]:'',
				 start_money:money?money[0]:'',
				 end_money:money?money[1]:'',
			}).then(res => {
				console.log(res)
				if (this.page.page == 1) {
					this.list = res.data.data.list;
					this.total = res.data.data.totalLimits;
				} else {
					if (res.data.data.list.length == 0) {
						this.$dialog.message(this.$t('tip.noMore'));
					} else {
						this.list = this.list.concat(res.data.data.list);
					}
				}
				// this.$loading.done();
			});
		},
		loadMore() {
			this.page.page += 1;
			this.getList();
		}
	}
};
</script>

<style lang="less" scoped>
.goodsList {
	.flex {
		display: flex;
		.content {
			flex: 1;
		}
	}
}
.ad {
	height: 540px;
}
.cate {
	font-size: 28px;
	font-weight: bold;
	line-height: 40px;
	margin: 30px 0;
	text-align: center;
}
.filter {
	padding: 0 7px;
	.main {
		// height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
		.options {
			display: flex;
			align-items: center;
			.item {
				font-size: 14px;
				padding: 0 30px;
				cursor: pointer;
			}
			.devide {
				&:first-child {
					display: none;
				}
				& + .item {
					padding: 10px 40px;
				}
				width: 1px;
				height: 30px;
				background: #bababa;
			}
		}
	}
	.total {
		font-size: 14px;
		margin-right: 40px;
	}
}
.list {
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 60px 7px;
	.item {
		width: calc((100vw - 60px) / 4);
		margin: 50px 6px 0 0;
	}
}
.more {
	height: 50px;
	line-height: 50px;
	text-align: center;
	border: 2px solid #bbb;
	margin: 0 21px 84px;
	font-size: 16px;
	cursor: pointer;
}
@media screen and (max-width: 500px) {
	.ad {
		height: 28.1vw;
	}
	.cate {
		font-size: 4vw;
		font-weight: bold;
		margin: 4vw 0;
		text-align: center;
	}
	.filter {
		padding: 0 7px;
		.main {
			height: 12vw;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #757575;
			border-bottom: 1px solid #757575;
			.options {
				width: 100%;
				display: flex;
				align-items: center;
				.item {
					font-size: 3vw;
					padding: 0 !important;
					flex: 1;
					text-align: center;
				}
				.devide {
					display: none;
				}
			}
		}
		.total {
			display: none;
			font-size: 14px;
			margin-right: 40px;
		}
	}
	.list {
		display: flex;
		flex-wrap: wrap;
		padding: 0 0 6vw 6px;
		.item {
			width: calc((100vw - 18px) / 2);
			margin: 6px 6px 0 0;
		}
	}
	.more {
		height: 12vw;
		line-height: 13vw;
		border: 1px solid #bbb;
		font-size: 4vw;
	}
}
</style>
