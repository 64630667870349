<template>
  <div class="nav-menu">
    <el-row>
      <div class="menu-title" @click="showMenu = !showMenu">
        <i class="el-icon-s-operation"></i>
        <span>Filtros</span>
      </div>
      <el-menu
        style="width: 100%"
        v-show="showMenu"
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        text-color="#888"
        active-text-color="#444"
        background-color="#fff"
      >
        <el-submenu index="1">
          <template slot="title">
            <span>Precio</span>
          </template>
          <div class="box">
            <el-slider
              v-model="sliderValue"
              tooltip-class="nav-slider"
              input-size="mini"
              range
              :max="20000"
              style="padding-right: 35px"
              @change="sliderChange"
            ></el-slider>
          </div>
        </el-submenu>
        <el-submenu index="2">
          <span slot="title">Marca</span>
          <!-- <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox
              class="box"
              v-for="city in cities"
              :label="city.label_name"
              :key="city.label_id"
              >{{ city.label_name }}</el-checkbox
            >
          </el-checkbox-group> -->
          <el-radio-group
            v-model="checkedCities"
          >
            <el-radio
            @click.native.prevent="handleCheckedCitiesChange(city.label_id)"
              class="box"
              v-for="city in cities"
              :label="city.label_id"
              :key="city.label_id"
              >{{ city.label_name }}</el-radio
            >
          </el-radio-group>
        </el-submenu>
        <!-- <el-submenu index="3">
					<span slot="title">Disponibilidad</span>
					<div class="box">
						<ul class="box-ul">
							<li v-for="item in type" :key="item">{{ item }}</li>
						</ul>
					</div>
				</el-submenu>
				<el-menu-item index="4"><span slot="title">Perros</span></el-menu-item> -->
      </el-menu>
    </el-row>
  </div>
</template>

<script>
import Api from "../../api/request.js";

export default {
  data() {
    return {
      showMenu: false,
      /* 多选 */
      checkedCities: "",
      cities: [],
      /* 滑块 */
      sliderValue: 0,
      /* 类别 */
      type: ["Kong", "Mordedores", "Pelotas"],
    };
  },
  created() {
    this.getBand();
  },
  methods: {
    sliderChange(e) {
      this.sliderValue = e;
      this.$emit("getList2", this.checkedCities, this.sliderValue);
    },
    getBand() {
      Api.Goods.band({
        type: 2,
      }).then((res) => {
        this.cities = res.data.data;
      });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleCheckedCitiesChange(value) {
      // this.checkedCities = value;
      value===this.checkedCities?this.checkedCities='':this.checkedCities=value;
      // console.log(this.checkedCities)
      this.$emit("getList2", this.checkedCities, this.sliderValue);


    },
  },
};
</script>
<style lang="less" scoped>
.nav-menu {
  /* width: 200px;
  flex: 1; */
  min-width: 220px;
  max-width: 400px;
  margin-right: 20px;
  position: fixed;
  z-index: 5;
  .menu-title {
    width: calc(100vw - 18px);
    line-height: 30px;
    padding-left: 18px;
    background-color: #fff;
  }
  .box {
    display: block;
    margin: 10px 20px 10px 40px;
    .box-ul {
      list-style: circle;
      color: #999;
      font-size: 14px;
      li {
        line-height: 28px;
        cursor: pointer;
        &:hover {
          color: #666;
        }
      }
    }
  }
  .nav-slider {
    background: black;
  }
}
/deep/.el-slider__bar {
  background: #333;
}
/deep/.el-slider__button {
  border: 2px solid #333;
}
/deep/.el-menu {
  border-right: none;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333;
  border-color: #333;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
</style>
